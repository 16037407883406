<template>
  <div>
    <router-link class="come-back" to="/admin/corruption_group">
      <div></div>
      Вернуться назад
    </router-link>
    <h2 class="title_categories">{{ $route.query.type === 'edit' ? 'Редактирование группы' : 'Создание группы' }}</h2>
    <tricolor-line class="card_containet__tricolor"/>
    <form @submit.prevent="addCountry" ref="addCountry">
      <div>
        <div class="content-sb">
          <p>Название группы<span>*</span></p>
          <input required placeholder="Введите название" type="text" name="title" v-model="form.title" class="input blue">
        </div>
        <div class="content-sb">
          <p>Название группы (Англ)</p>
          <input placeholder="Введите название (Англ)" type="text" name="titleEng" v-model="form.titleEng" class="input blue">
        </div>
        <div v-if="$route.query.type !== 'edit'" class="content-sb">
          <p>Выберите родительскую секцию<span>*</span></p>
          <select required @change="changeSection" class="input blue" name="SectionId">
            <option v-if="$route.query.type === 'create'" disabled selected>Выберите секцию</option>
            <option v-for="item in $store.getters.getCorruptionSection"
                    :key="`getCorruptionSection${item.id}`" :value="item.id">
              {{ item.title }}
            </option>
          </select>
        </div>
        <div class="content-sb">
          <p>Тип группы<span>*</span></p>
          <select required @change="changeType" class="input blue">
            <option v-if="$route.query.type === 'create'" disabled selected>Выберите тип группы</option>
            <option value="1" :selected="form.is_group === true">Группа документов</option>
            <option value="0" :selected="form.is_group === false">Ссылка</option>
          </select>
        </div>
        <div v-if="form.is_group === 0 || form.is_group === false" class="content-sb">
          <p>Ссылка<span>*</span></p>
          <input required placeholder="Введите название" type="text" v-model="form.link" class="input blue">
        </div>
      </div>

      <footer class="content-sb btns-create-form">
        <router-link to="/admin/corruption_group" class="btn-white">Отмена</router-link>
        <input type="submit" class="btn-blue" :value="$route.query.type === 'edit' ? 'Изменить' : 'Сохранить'"/>
      </footer>
    </form>
  </div>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'

export default {
  name: 'CreateCorruptionSection',
  data () {
    return {
      form: this.$route.query.type === 'edit' ? this.$store.getters.getDetailCorruptionGroup : {
        title: '',
        SectionId: '',
        is_group: '',
        link: '',
        titleEng: ''
      }
    }
  },
  methods: {
    addCountry () {
      const formData = new FormData(this.$refs.addCountry)
      formData.append('is_group', this.form.is_group)
      if (this.$route.query.type === 'edit') {
        formData.append('is_group', Number(this.form.is_group))
        if (this.form.is_group === 0 || this.form.is_group === false) formData.append('link', this.form.link)
        this.$store.dispatch('editCorruptionGroup', { id: this.form.id, data: formData })
      } else {
        formData.append('is_group', this.form.is_group)
        if (this.form.is_group === 0) formData.append('link', this.form.link)
        this.$store.dispatch('AddCorruptionGroup', { id: this.form.SectionId, data: formData })
      }
    },
    changeSection (e) {
      this.form.SectionId = e.target.value
    },
    changeType (e) {
      this.form.is_group = Number.parseInt(e.target.value)
    }
  },
  mounted () {
    if (this.$route.query.type === 'edit' && this.$store.getters.getDetailCorruptionGroup === null) {
      this.$router.push('/admin/corruption_group')
    }
    this.$store.dispatch('setCorruptionSection')
  },
  components: {
    TricolorLine
  }
}
</script>

<style lang="scss">
.btns-create-form {
  border-top: 1px solid #D5D6D9;
  padding-top: 2.5rem;
  margin-top: 2.5rem;
}

.content-sb {
  margin-bottom: 1.25rem;

  span {
    color: red;
  }
}

.select, .input {
  width: 33.75rem;
}
</style>
